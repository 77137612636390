<template>
  <nav id="nav" class="nav" role="navigation" ref="navFocusTrap">
    <div>
      <div class="nav-controls" ref="navControls">
        <button
          type="button"
          :class="[{ 'button-override-black': isMenuOpen }, 'button button-shadow button-red nav-control-open']"
          @click="toggleMenu"
          @focus="onFocus"
          :aria-expanded="isMenuOpen?'true':'false'"
          aria-controls="menu"
        >
          <span v-if="!isMenuOpen">Menu</span>
          <span v-if="isMenuOpen">Close Menu</span>
          <span :class="[{ 'is-active': isMenuOpen }, 'hamburger hamburger--collapse']">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </span>
        </button>
      </div>
      <div id="menu" :class="[{ 'is-active': isMenuOpen }, 'nav-overlay']" ref="navOverlay" aria-label="Primary" role="navigation">
        <menu :class="[{ 'animate-menu': isMenuOpen }, 'nav-menu']">
          <router-link @click="closeMenu" to="/">Home</router-link>
          <!-- <router-link @click="closeMenu" to="/about">About</router-link> -->
          <router-link @click="closeMenu" to="/code">Code</router-link>
          <router-link @click="closeMenu" to="/photography">Photography</router-link>
          <a class="nav-external-link nav-external-link-first" target="_blank" href="https://www.instagram.com/eightbitkent/" rel="noopener" title="Kent Widman on Instagram">
            <span class="icon-launch"></span>@eightbitkent
          </a>
          <a class="nav-external-link" href="https://soundcloud.com/kent-widman" target="_blank" rel="noopener" title="Kent Widman on Soundcloud">
            <span class="icon-launch"></span>soundcloud
          </a>
          <a class="nav-external-link" href="https://www.linkedin.com/in/kent-widman/" target="_blank" rel="noopener" title="Kent Widman on LinkedIn">
            <span class="icon-launch"></span>linkedin
          </a>
        </menu>
      </div>
    </div>
  </nav>
</template>

<script>
import * as focusTrap from 'focus-trap';

export default {
  name: 'SiteNavigation',
  data() {
    return {
      isMenuOpen: false,
      // scroll behavior
      didScroll: false,
      lastScrollTop: 0,
      delta: 5,
      navbarHeight: 60,
      ft: null,
    };
  },
  mounted() {
    this.setup();

    // add focus trap modal
    this.ft = focusTrap.createFocusTrap(this.$refs.navFocusTrap);
  },
  watch: {
    isMenuOpen(val) {
      const htmlEl = document.getElementsByTagName('html')[0];
      if (val) {
        htmlEl.classList.add('no-scroll', 'nav-open');

        this.hideContentForScreenReaders();

        // add focus trap modal
        this.ft.activate();

        // setTimeout(() => {
        //   this.ft.updateContainerElements();
        // }, 1000);
      } else {
        htmlEl.classList.remove('no-scroll', 'nav-open');

        this.showContentForScreenReaders();

        setTimeout(() => {
          this.$refs.navOverlay.scrollTo(0, 0);
        }, 300);

        this.ft.deactivate();
      }
    },
  },
  methods: {
    openMenu() {
      this.isMenuOpen = true;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    setup() {
      document.addEventListener('scroll', this.onScroll);

      setInterval(this.checkInterval, 250);
    },
    onScroll() {
      this.didScroll = true;
    },
    onFocus() {
      this.lastScrollTop = 0;
      this.$refs.navControls.classList.remove('nav-up');
    },
    checkInterval() {
      if (this.didScroll) {
        this.hasScrolled();
        this.didScroll = false;
      }
    },
    hasScrolled() {
      const st = window.scrollY;

      // Make sure they scroll more than delta
      if (Math.abs(this.lastScrollTop - st) <= this.delta) {
        return;
      }

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > this.lastScrollTop && st > this.navbarHeight && !this.isMenuOpen) {
        // Scroll Down
        this.$refs.navControls.classList.add('nav-up');
      } else {
        // Scroll Up
        const windowHeight = window.innerHeight;
        const documentHeight = document.body.scrollHeight;
        if (st + windowHeight < documentHeight) {
          this.$refs.navControls.classList.remove('nav-up');
        }
      }

      this.lastScrollTop = st;
    },
    hideContentForScreenReaders() {
      const elementsToHide = document.querySelectorAll('#main');
      elementsToHide.forEach((e) => { e.setAttribute('aria-hidden', 'true'); });
    },
    showContentForScreenReaders() {
      const elementsToShow = document.querySelectorAll('#main');
      elementsToShow.forEach((e) => { e.setAttribute('aria-hidden', 'false'); });
    },
  },
  destoyed() {
    const htmlEl = document.getElementsByTagName('html')[0];
    htmlEl.classList.remove('no-scroll', 'nav-open');

    if (this.ft) {
      this.ft.deactivate();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@use "sass:math";
@import '../scss/common';

.nav {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

.nav-controls {
  pointer-events: visible;
  position: fixed;
  right: 20px;
  top: 20px;
  transform: translate(0, 0);
  transition: transform 0.3s ease-in-out,
              top 0s linear 0.3s;
  z-index: 200;
}

.nav-control-open {
  .hamburger {
    margin-left: 8px;
  }
}

.nav-overlay {
  pointer-events: none;
  background-color: $color-off-white;
  // background-image: url('/images/bg-menu.jpg');
  // background-position: top center;
  background-size: math.div(2500px, 2) math.div(1600px, 2);
  background-repeat: no-repeat;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 89;
  transition: transform 0.2s ease-in-out,
              opacity 0.2s ease-in-out,
              visibility 0s ease-in-out 0.3s;
  transform-origin: top center;
  opacity: 0;
  visibility: hidden;

  &.is-active {
    pointer-events: all;
    transition: transform 0.2s ease-in-out,
                opacity 0.2s ease-in-out,
                visibility 0s ease-in-out 0s;
    transform-origin: top center;
    opacity: 1;
    visibility: unset;
  }
}

.nav-menu {
  padding: 110px 0 90px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq($from: mobile) {
    padding: 160px 0 90px 0;
  }

  @include mq($from: tablet-large) {
    padding: 90px 0 90px 0;
  }

  a {
    text-decoration: none;
    font-size: 1.625rem;
    color: $color-black;
    transition: transform 0.2s ease-in-out 0.5s,
                opacity 0.2s ease-in-out,
                visibility 0s ease-in-out 0.3s;
    transform-origin: top center;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 100px);

    @include mq($from: mobile) {
      font-size: 2rem
    }
  }

  &.animate-menu {
    a {
      transform: translate(0, 0);
      transition: transform 0.5s cubic-bezier(0,.66,.48,1),
                  opacity 0.5s cubic-bezier(0,.66,.48,1),
                  visibility 0s cubic-bezier(0,.66,.48,1) 0s;
      opacity: 1;
      visibility: unset;
    }

    a:nth-child(1) {
      transition-delay: 0.5s - 0.3s;
    }
    a:nth-child(2) {
      transition-delay: 0.55s - 0.3s;
    }
    a:nth-child(3) {
      transition-delay: 0.60s - 0.3s;
    }
    a:nth-child(4) {
      transition-delay: 0.65s - 0.3s;
    }
    a:nth-child(5) {
      transition-delay: 0.70s - 0.3s;
    }
    a:nth-child(6) {
      transition-delay: 0.75s - 0.3s;
    }
    a:nth-child(7) {
      transition-delay: 0.80s - 0.3s;
    }
    a:nth-child(8) {
      transition-delay: 0.85s - 0.3s;
    }
    a:nth-child(9) {
      transition-delay: 0.90s - 0.3s;
    }
    a:nth-child(10) {
      transition-delay: 0.95s - 0.3s;
    }
    a:nth-child(11) {
      transition-delay: 1.00s - 0.3s;
    }
    a:nth-child(12) {
      transition-delay: 1.05s - 0.3s;
    }
    a:nth-child(13) {
      transition-delay: 1.10s - 0.3s;
    }
  }

  a + a {
    margin-top: 1.2em;
  }
}

.nav-external-link {
  span {
    font-size: 28px;
    margin-right: 0.4em;
  }
}

// .nav-external-link-first {
//   margin-top: 3vh !important;

//   @include mq($from: tablet-large) {
//     margin-top: 5vh !important;
//   }
// }
</style>
