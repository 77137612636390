<template>
  <section id="intro" class="intro neo">
    <div class="intro-text-wrapper">
      <div class="intro-text">
        <h1>
          <span class="intro-text__part-1" data-swap-text="namdiv tnek">kent widman</span>
          <span class="intro-text__part-2" data-swap-text="digital dictator">technical director</span>
        </h1>
        <h2>
          <span class="intro-text__part-3">
            <span data-swap-text="lead developer">hand crafted</span>
            <span>&nbsp;</span>
            <span data-swap-text="code guru">digital blitz</span>
          </span>
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
// import swapText from '../lib/swap-text';

export default {
  name: 'IntroSection',
  // mounted() {
  //   swapText.init(document.getElementById('intro'));
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../scss/common';

.intro {
  background-image: url('~@/assets/intro.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  height: 100%;
  min-height: 500px;
}

.intro-text {
  h1,
  h2 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.intro-text__part-1,
.intro-text__part-2,
.intro-text__part-3 {
  display: block;
  font-family: 'Fira Mono', monospace;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #ffffff;

  span {
    position: relative;

    &:before {
      content: attr('data-text');
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.intro-text__part-1 {
  font-size: 13.5vw;
  margin-bottom: 2vw;
  text-align: center;

  @media screen and (min-width: 720px) {
    font-size: 100px;
    margin-bottom: 0.10em;
  }
}

.intro-text__part-2 {
  font-size: 8.2vw;
  margin-bottom: 2vw;
  text-align: center;
  color: #44f5f3;

  @media screen and (min-width: 720px) {
    font-size: 60.8px;
    margin-bottom: 0.2em;
  }
}

.intro-text__part-3 {
  font-size: 5.9vw;
  color: #f639ed;
  text-align: center;
  line-height: 1.1em;

  @media screen and (min-width: 720px) {
    font-size: 42.5px;
  }
}

.intro-text-wrapper {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
}

.intro-text {
  display: block;
  margin: auto;
  max-width: 668px;
}
</style>
