<template>
  <section class="lights bgc-purple neo">
    <canvas id="lightsApp"></canvas>
    <div class="about-text">
      <h3>
        <span class="color-purple">Digital</span> <span>is a canvas for</span><br>
        <span class="color-purple">expressing</span> <span>ideas</span><br>
        <span>convey</span> <span class="color-purple">emotions</span><br>
        <span class="color-purple">pushing</span> <span>bounderies</span><br>
        <span>and</span> <span class="color-purple">change</span> <span>minds</span>
      </h3>
    </div>
  </section>
</template>

<script>
import lights from '@/lib/lights';

export default {
  name: 'LightsSection',
  mounted() {
    lights.init(document.getElementById('lightsApp'));
  },
  beforeUnmount() {
    lights.destroy();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../scss/common';

.lights {
  position: relative;
  padding: 0 !important;
}

#lightsApp {
  display: block !important;
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
}

.about {
  height: 100%;
  min-height: 500px;
  // background-size: contain;
  // padding-bottom: 10px;
}

.about-text {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  left: 10%;
  right: 10%;
  color: #ffffff;

  @media screen and (min-width: 641px) {
    right: 15vh;
  }

  h3 {
    margin: 0;
    text-align: right;
    font-size: 11vw;

    @media screen and (min-width: 641px) {
      font-size: 60px;
    }
  }

  span span {
    opacity: 0;
  }

  span.glitch {
    &:nth-child(1) {
      &:before,
      &:after {
        animation-delay: 0s;
        opacity: inherit;
      }
    }
    &:nth-child(2) {
      &:before,
      &:after {
        animation-delay: 5s;
      }
    }
    &:nth-child(3) {
      &:before,
      &:after {
        animation-delay: 3s;
      }
    }
    &:nth-child(4) {
      &:before,
      &:after {
        animation-delay: 7s;
      }
    }
    &:nth-child(5) {
      &:before,
      &:after {
        animation-delay: 1s;
      }
    }
    &:nth-child(6) {
      &:before,
      &:after {
        animation-delay: 8s;
      }
    }
    &:nth-child(7) {
      &:before,
      &:after {
        animation-delay: 2s;
      }
    }
    &:nth-child(8) {
      &:before,
      &:after {
        animation-delay: 4s;
      }
    }
  }
}
</style>
