import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

/*
Name    | Free | Paid | Prefix | NPM Package (free)                  | NPM package (paid)
---------------------------------------------------------------------------------------------------------
Solid   | Yes  | Yes  | fas    | @fortawesome/free-solid-svg-icons   | @fortawesome/pro-solid-svg-icons
Regular | Yes  | Yes  | far    | @fortawesome/free-regular-svg-icons | @fortawesome/pro-regular-svg-icons
Light   | No   | Yes  | fal    |                                     | @fortawesome/pro-light-svg-icons
Brands  | Yes  | No   | fab    | @fortawesome/free-brands-svg-icons  |
*/

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedin,
  faSpotify,
  faInstagram,
  faStackOverflow,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';

/*
['far', 'envelope']
['fab', 'linkedin-in']
['fab', 'instagram']
['fab', 'spotify']
['fab', 'spotify']
*/

library.add(
  faEnvelope,
  faLinkedin,
  faSpotify,
  faInstagram,
  faStackOverflow,
  faGithub,
);

export default FontAwesomeIcon;
