<template>
  <section id="code" class="code dark bgc-turquoise neo padded">
    <h2>Code Examples</h2>
    <p>
      Check out some of my coding demos, and samples from past projects.
    </p>

    <div class="code-examples">
      <div class="code-example">
        <img src="../assets/code/kentris/title_bg.gif" alt="">
        <div>
          <h3>Kentris</h3>
          <p>
            A Tetris clone of the orginal NES release.
            Coded in ES6 javascript, html5 canvas, and spitemaps graphics from the orginal game.
            It has most features coded such as gameplay and highscore.
          </p>
          <router-link to="/code/kentris" class="button button-white-hover-red">Play Kentris</router-link>
        </div>
      </div>
      <div class="code-example">
        <img src="../assets/code/elevator-simulator/elevator-simulator.png" alt="">
        <div>
          <h3>Elevator Simulator</h3>
          <p>
            The elevator simulator presents a charming 8-bit world bustling with activity across various floors.
            Characters patiently queue on different floors, some eager to board and others disembarking. <b>In progress.</b>
          </p>
          <router-link to="/code/elevator-simulator" class="button button-white-hover-red">
            View Elevator Simulator</router-link>
        </div>
      </div>
      <div class="code-example">
        <img src="../assets/code/product-filtering/product-filtering.jpg" alt="">
        <div>
          <h3>Product Finder Demo</h3>
          <p>
            This demonstration showcases a system for filtering products by multiple tags and categories.
            The resulting products are displayed in the a hierarchical tree structure.
          </p>
          <router-link to="/code/product-filtering" class="button button-white-hover-red">View Coding Example</router-link>
        </div>
      </div>
      <div class="code-example">
        <img src="../assets/code/mustache-loadbar/screen-shoot.gif" alt="">
        <div>
          <h3>Mustache-Loadbar</h3>
          <p>
            Imagine a shady character on your screen, a clean-shaven individual with an empty space above their lips.
            As the site loads the mushtash grows as a progress bar.
          </p>
          <router-link to="/code/mustache-loadbar" class="button button-white-hover-red">Watch that mustache grow.</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CodeSection',
  data() {
    return {
      email: '',
      time: 10,
    };
  },
};
</script>

<style lang="scss">
@import '../scss/common';
.code {
  background-position: top center;
  background-attachment: fixed;
  height: auto !important;

  h1 {
    padding-top: 50px;
    margin-bottom: 10px;
    font-size: 20vw;

    @media screen and (min-width: 480px) {
      font-size: 150px;
    }
  }

  .email {
    font-size: 24px;
    text-decoration: none;
    color: #fff;
    width: 100%;
    text-align: center;
  }

  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.code-examples {
  max-width: 800px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.code-example {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  > img {
    width: calc(30% - 30px);
  }

  > div {
    width: calc(70% - 30px);
  }
}

.code-example + .code-example {
  margin-top: 80px;
}

.info,
.social-links {
  text-align: left;
  max-width: 600px;
  font-size: 1.4em;
  margin: 60px auto;

  @media screen and (min-width: 480px) {
    font-size: 40px;
  }

  & > * {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }
}

.social-links__icon {
  width: calc(15% - 15px);
  text-align: right;

  @media screen and (min-width: 480px) {
    width: calc(15% - 15px);
  }
}

.social-links__text {
  width: calc(85% - 15px);

  @media screen and (min-width: 480px) {
    width: calc(85% - 15px);
  }

  &-small {
    @media screen and (min-width: 480px) {
      font-size: 28px;
    }

    @media screen and (min-width: 480px) {
      font-size: 28px;
    }
  }
}
</style>
