<template>

  <Intro />
  <Lights />
  <About />
  <Houndini />
  <Code />
  <Work />
  <HomePhotography />
  <Contact />
  <SiteFooter />
</template>

<script>
// @ is an alias to /src
// import GlobalNav from '@/components/GlobalNav.vue';
import Intro from '@/components/Intro.vue';
import Lights from '@/components/Lights.vue';
import About from '@/components/About.vue';
import Houndini from '@/components/Houndini.vue';
import Code from '@/components/Code.vue';
import Work from '@/components/Work.vue';
import HomePhotography from '@/components/HomePhotography.vue';
import Contact from '@/components/Contact.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'HomeView',
  components: {
    Intro,
    Lights,
    About,
    Houndini,
    Code,
    Work,
    Contact,
    HomePhotography,
    SiteFooter,
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Kent Widman Web Developer',
    // meta: [
    //   { charset: 'utf-8' },
    //   { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    // ],
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '../scss/common';
  @import '../scss/theme/neo';
  @import '../scss/views/home';
</style>
