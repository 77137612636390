<template>
  <section id="home-photography" class="home-photography neo light padded">
    <h2>Photography</h2>
    <div class="blurb">
    <p>Explore Chicago's vibrant music scene through my lens.</p>
    <router-link class="button button-red" to="/photography">View Show Photos</router-link>
    </div>
  </section>
</template>

<script>
// import swapText from '../lib/swap-text.js';
export default {
  name: 'HomePhotography',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../scss/common';

.home-photography {
  max-height: 40vw;
  min-height: 500px;
  background-image: url('~@/assets/photography_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;

  h2 {
    color: $color-white;
    font-size: 12vw;
    margin: 0;

    @media screen and (min-width: 1200px) {
      font-size: 145px;
      margin-bottom: 0.5em;
    }
  }

  p {
    color: $color-white;
    line-height: 1.75;
  }

  .button {
    font-size: 1.2em;
  }

  .blurb {
    max-width: 400px;
    margin: 0 auto;;
  }
}
</style>
