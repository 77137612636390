<template>
<footer class="footer">
  <div class="footer-lower footer-wrapper">
    <p class="footer-copyright">© <span>{{ year }}</span> KentWidman.com</p>
  </div>
</footer>
</template>

<script>
export default {
  name: 'FooterSection',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../scss/common';

.footer {
  color: $color-white;
  background-color: $color-black;
  margin: 0 auto 0;
  max-width: 1440px;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.padding {
  padding: 20px;
}

.footer-buttons {
  margin-top: 64px;
  text-align: center;
}

.footer-lower  {
  padding: 48px 20px 65px;
}

.footer-copyright {
  text-align: center;
}

.footer-links {
  text-align: center;
}
</style>
