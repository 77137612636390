<template>
  <section class="work light bgc-black neo padded">
    <h2 class="work-title">Digital Work</h2>
    <Project
      v-for="(workitem, workitemIndex) in work"
      :key="workitemIndex"
      class="workitem"
      :data="workitem"
    />
  </section>
</template>

<script>
import Project from './Project.vue';

const data = require('../data/work.json');

export default {
  name: 'WorkSection',
  components: {
    Project,
  },
  data() {
    return {
      work: data,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../scss/common';

.work {
  background-position: top center;
  background-attachment: fixed;
  height: auto;
  padding-bottom: 10px;
  overflow: hidden;
}

.work-title {
  margin-bottom: 1.0em !important;
}
</style>
