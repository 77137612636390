<template>
  <section class="about bgc-black light neo padded">
    <h2>About</h2>
    <p>
      Kent Widman is a Technical Director at Cramer-Krasselt, the Second-largest
      independent advertising agency in the United States.
      Born and raised in a ski-town in northern Sweden, music, art, and computers,
      have always been a big part of his life and identity.
      Today Kent provides web consulting, codes full-stack websites, and web applications
      while mentoring developers, recording music at his home studio, play and go to shows, and photography.
    </p>
    <h2>Skills</h2>
    <div class="skills">
      <div>
        <h3>Front-End</h3>
        <ul>
          <li>HTML5</li>
          <li>CSS3, SASS/SCSS</li>
          <li>JavaScript(ES6)</li>
          <li>Worpress, CraftCMS theming</li>
          <li>Vue.js, React</li>
          <li>Canvas</li>
        </ul>
      </div>
      <div>
        <h3>Back-End</h3>
        <ul>
          <li>PHP Frameworks (Custom Worpress Plugins, CraftCMS, Laravel, Code Igniter)</li>
          <li>Node.js</li>
          <li>MySQL</li>
          <li>MongoDB</li>
          <li>Mongoose</li>
          <li>Express</li>
        </ul>
      </div>
      <div>
        <h3>Infrastructure</h3>
        <ul>
          <li>Centos/Redhat server admin</li>
          <li>Apache Web Server Configuration</li>
          <li>Server Hardening</li>
          <li>Web Aplication Firewall</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutSection',
};
</script>

<style lang="scss">
@import '../scss/common';

.about {
  background-position: top center;
  background-attachment: fixed;
  text-align: center;
  height: auto;
}

.skills {
  max-width: 800px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  column-gap: 30px;
  text-align: left;

  @media screen and (min-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ul {
    list-style: none;
    padding-left: 0;

    font-size: 20px;
    line-height: 1.6;

    @media screen and (min-width: 480px) {
      font-size: 18px;
    }
  }
}
</style>
