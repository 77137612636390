<template>
<article class="work-project">
  <h1 class="work-project-title">{{title}}</h1>
  <div class="awards">
    <div class="award" v-for="award in awards" :key="award" >
      <svg class="awwwards" v-if="award === 'awwwards'" width="141" height="22.3" viewBox="0 0 280 44.3">
        <path class="logo-fill" id="logo-a1" d="M1.2,20.8c1-5.1,5.5-8.7,11.9-8.7c7.4,0,13.1,3.1,13.1,13.1v18.6h-6.3l-0.8-4c-2.2,2.7-5.1,4.5-9.6,4.5 C4.2,44.3,0,41,0,34.8s5.5-10.2,13.3-10.2h5.2v-1.4c0-3.5-2.6-4.6-5.5-4.6c-2.2,0-4.4,0.7-5.4,3.6L1.2,20.8z M11.3,37.8 c3.5,0,7.2-2.4,7.2-6.4v-0.6h-5.1c-3.3-0.1-5.3,1.2-5.3,3.9C8.1,36.2,9.2,37.8,11.3,37.8z"></path>
        <path class="logo-fill" id="logo-a2" d="M151,20.8c1-5.1,5.5-8.7,11.9-8.7c7.4,0,13.1,3.1,13.1,13.1v18.6h-6.3l-0.8-4c-2.2,2.7-5.1,4.5-9.6,4.5 c-5.3,0-9.5-3.3-9.5-9.5s5.5-10.2,13.3-10.2h5.2v-1.4c0-3.5-2.6-4.6-5.5-4.6c-2.2,0-4.4,0.7-5.4,3.6L151,20.8z M161.1,37.8 c3.5,0,7.2-2.4,7.2-6.4v-0.6h-5.1c-3.3,0-5.3,1.2-5.3,4C157.9,36.2,159,37.8,161.1,37.8z"></path>
        <path class="logo-fill" id="logo-r" d="M183,43.9V12.6h7.7v5.1c2.2-3.7,5.7-5.6,9.2-5.6c1.4,0,2.5,0.2,3.8,0.6l-1.4,7.1c-0.9-0.6-1.9-0.9-3.2-0.9 c-4.5,0-8.4,3.7-8.4,13.8v11.1L183,43.9L183,43.9z"></path>
        <path class="logo-fill" id="logo-d" d="M220.1,12.1c3.2,0,6.2,1.6,7.6,3.3V0h7.7v43.9h-7.7v-3.2c-0.9,1.6-3.7,3.7-7.6,3.7c-9.4,0-14.2-6.5-14.2-16.1 S210.7,12.1,220.1,12.1z M221.2,37.8c4,0,7.2-3.3,7.2-9.5s-3.2-9.5-7.2-9.5s-7.2,3.3-7.2,9.5S217.2,37.8,221.2,37.8z"></path>
        <path class="logo-fill" id="logo-s" d="M263.2,17.1l-4.6,4.2c-1.4-1.9-3.5-2.7-5.2-2.7c-1.5,0-2.9,0.9-2.9,2.6c0,1.4,1.1,2.4,3,3 c4.3,1.6,11.3,2.9,11.3,9.6c0,5.7-4.3,10.4-11.1,10.4c-6.5,0-10.9-4.6-12.4-8.4l6.1-3.3c1.1,3,3.2,5.2,6.3,5.2 c2.4,0,3.9-1.1,3.9-3.4c0-2.6-4-3.2-7.8-4.8c-3.4-1.4-6.3-3.6-6.3-8.4s3.9-9.1,10-9.1C257.6,12,261.3,14.1,263.2,17.1z"></path>
        <polygon class="logo-fill" id="logo-w2" points="103,12.6 97.5,34.1 92.2,12.6 85,12.6 79.8,34.1 75.4,21.6 74.9,23.1 72.4,31.1 76.4,43.9 83.2,43.9  88.6,23.7 94,43.9 100.8,43.9 110.8,12.6"></polygon>
        <polygon class="logo-fill" id="logo-w3" points="140.2,12.6 134.6,34.1 129.3,12.6 122.1,12.6 116.9,34.1 112.5,21.6 112,23.1 109.5,31.1 113.5,43.9  120.3,43.9 125.7,23.7 131.1,43.9 137.9,43.9 147.9,12.6"></polygon>
        <path class="logo-fill" id="logo-w1" d="M73.7,12.6l-10,31.2h-6.8l-5.4-20.2l-5.4,20.3h-6.8l-10-31.2h7.8l5.6,21.4L48,12.7h7.1l5.3,21.4L66,12.7 L73.7,12.6L73.7,12.6z"></path>
        <path class="logo-fill" id="logo-p" d="M274.8,33.8c3,0,5.2,2.2,5.2,5.2s-2.2,5.2-5.2,5.2s-5.2-2.2-5.2-5.2S271.9,33.8,274.8,33.8z"></path>
      </svg>
    </div>
  </div>
  <p>{{description}}</p>
  <div :class="['pictures', 'images', mobileLayout]">
    <!-- <a :href="'work/'+slug">See Work</a> -->
    <div :class="['pictures-wrapper', pictureClass]" >
      <button v-for="image in images" :key="image.title" @click="rotateLeft" @keydown.left=rotateLeft @keydown.right=rotateRight class="work-image">
        <picture>
          <source media="(min-width: 850px)" :srcset="image.srcDesktop">
          <source media="(min-width: 650px)" :srcset="image.srcTable">
          <source media="(min-width: 465px)" :srcset="image.srcMobile">
          <img :width="image.width" :height="image.height" :src="image.srcDesktop" :alt="image.title" loading="lazy">
        </picture>
      </button>
    </div>
  </div>
  <div v-if="videos" class="video-wrapper">
    <video muted autoplay v-for="video in videos" :key="video.srcDesktop">
      <source media="min-width: 850px" :src="video.srcDesktop">
    </video>
  </div>
  <div class="work-details">
    <div class="links" v-if="links">
      <p v-for="link in links" :key="link.url" class="btn-center">
        <a class="btn btn-black" :href="link.url">{{link.name}}</a>
      </p>
    </div>
  </div>
</article>
</template>

<script>
export default {
  name: 'ProjectSection',
  props: {
    data: Object,
  },
  data() {
    console.log(this.data);
    const data = { ...this.data };
    data.currentImage = 0;
    return data;
  },
  computed: {
    pictureClass() {
      const index = (this.currentImage % 3) + 1;
      return `select-${index}`;
    },
  },
  methods: {
    rotateLeft() {
      this.currentImage += 1;
    },
    rotateRight() {
      this.currentImage -= 1;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../scss/common';

.work-project {
  margin: 0 auto 10vw auto;
  max-width: 1200px;

  @media screen and (min-width: 1200px) {
    margin: 0 auto 120px auto;
  }
}

.work-project-title {
  font-size: 12vw !important;
  margin: 0;

  @media screen and (min-width: 1200px) {
    font-size: 145px !important;
  }
}

.work-details {
  a {
    color: #fff;
  }
}

.work-image {
  padding: 0;
  margin-bottom: 30px;
}

.pictures {
  display: block;
  position: relative;
  max-width: 1400px;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;

  @media screen and (min-width: 480px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .pictures-wrapper {
    @media screen and (min-width: 480px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &.scroll {
    @media screen and (max-width: 479px) {
      overflow: auto;
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;

      .pictures-wrapper {
        width: calc(300% - 120px);
        display: flex;
        padding-left: 30px;
        padding-right: 30px;
      }

      picture {
        max-width: calc(100% - 90px);
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.stacked {
    @media screen and (max-width: 479px) {
      picture {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .work-image {
    display: block;
    height: auto;
    position: absolute;
    z-index: 1;
    transform: scale(1, 1) translate(0, 0);
    transition: all ease-in-out 300ms;
    box-shadow: rgba(0, 0, 0, 0.21) 10px 10px 10px;
    border: 2px solid white;
    cursor: pointer;
    box-sizing: border-box;

    img {
      display: block;
      height: auto
    }

    &:nth-child(2) {
      z-index: 2;
      transform: scale(0.5, 0.5) translate(calc(-50% - 60px), 135%);
    }

    &:nth-child(3) {
      z-index: 2;
      transform: scale(0.5, 0.5) translate(calc(50% + 60px), 135%);
    }
  }

  .pictures-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 92%;

    &.select-1 {
      .work-image {
        &:nth-child(1) {
          z-index: 1;
          transform: scale(1, 1) translate(0, 0);
        }

        &:nth-child(2) {
          z-index: 2;
          transform: scale(0.5, 0.5) translate(calc(-50% - 60px), 135%);
        }

        &:nth-child(3) {
          z-index: 2;
          transform: scale(0.5, 0.5) translate(calc(50% + 60px), 135%);
        }
      }
    }

    &.select-2 {
      .work-image {
        &:nth-child(1) {
          z-index: 2;
          transform: scale(0.5, 0.5) translate(calc(50% + 60px), 135%);
        }

        &:nth-child(2) {
          z-index: 1;
          transform: scale(1, 1) translate(0, 0);
        }

        &:nth-child(3) {
          z-index: 2;
          transform: scale(0.5, 0.5) translate(calc(-50% - 60px), 135%);
        }
      }
    }

    &.select-3 {
      .work-image {
        &:nth-child(1) {
          z-index: 2;
          transform: scale(0.5, 0.5) translate(calc(-50% - 60px), 135%);
        }

        &:nth-child(2) {
          z-index: 2;
          transform: scale(0.5, 0.5) translate(calc(50% + 60px), 135%);
        }

        &:nth-child(3) {
          z-index: 1;
          transform: scale(1, 1) translate(0, 0);
        }
      }
    }
  }
}

picture {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;

  > img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.awwwards {
  fill: #FFF;
  z-index: 0;
  transition: all 0.3s;
}
</style>
