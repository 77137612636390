<template>
  <section id="code" class="houndini light bgc-purple neo padded">
    <h2>Houndini Chrome Plugin</h2>
    <h3 class="tag-line">Now you see it, now you don't.</h3>
    <div class="houndini-video">
      <video preload="auto" playsinline autoplay muted loop>
        <source src="/videos/houndini.mp4" type="video/mp4" />
      </video>
    </div>
    <div>
      <p>Escape the Interruption: Say Goodbye to Pre-Roll Video Ads</p>
      <p>Imagine enjoying your favorite shows without pesky pre-roll video ads. With Houndini, a simple click or keyboard shortcut(Ctrl + B (Windows) or Command + B (Mac)) skips the ads, giving you uninterrupted viewing across various streaming platforms.</p>
      <p>Elevate your streaming experience with Houndini – because your time is too valuable to be interrupted.</p>
    </div>
    <p class="links">
      <a class="btn btn-purple" href="https://chrome.google.com/webstore/detail/houndini/immldcpimndbobdcmdanpoojgeinpioi" target="_blank">
        Download free from chrome webstore
      </a>
      <a btn btn-purple href="https://github.com/kentwidman/houndini">Github</a>
    </p>
  </section>
</template>

<script>
export default {
  name: 'CodeSection',
  data() {
    return {
      email: '',
      time: 10,
    };
  },
};
</script>

<style lang="scss">
@import '../scss/common';

.tag-line {
  text-align: center;
}

.houndini-video video {
  display: block;
  margin: 3em auto;;
}

.links{
  display: flexbox;
  flex-wrap: wrap;
  justify-content: center;

  a + a {
    margin-left: 2em;
  }
}
</style>
