<template>
  <section class="contact light bgc-purple neo padded">
    <h2>contact</h2>
    <div class="social-links">
      <div>
        <span class="social-links__icon"><font-awesome-icon :icon="['fas', 'envelope']" /></span>
        <span class="social-links__text social-links__text-small">{{ email }}</span>
      </div>
      <a href="https://www.linkedin.com/in/kent-widman/">
        <span class="social-links__icon"><font-awesome-icon :icon="['fab', 'linkedin']" /></span>
        <span class="social-links__text">@kent-widman</span>
      </a>
      <a href="https://www.instagram.com/eightbitkent/">
        <span class="social-links__icon"><font-awesome-icon :icon="['fab', 'instagram']" /></span>
        <span class="social-links__text">@eightbitkent</span>
      </a>
      <a href="https://open.spotify.com/artist/4lcGOrD8Lve5FVMYhnezkp">
        <span class="social-links__icon"><font-awesome-icon :icon="['fab', 'spotify']" /></span>
        <span class="social-links__text">Love in October</span>
      </a>
      <a href="https://open.spotify.com/artist/4lcGOrD8Lve5FVMYhnezkp">
        <span class="social-links__icon"><font-awesome-icon :icon="['fab', 'spotify']" /></span>
        <span class="social-links__text">Eight Bit Tiger</span>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactSection',
  data() {
    return {
      email: '',
      time: 10,
    };
  },
  mounted() {
    const timer = setInterval(
      () => {
        this.time -= 1;
        if (this.time > 0) {
          this.email = this.time;
        } else {
          clearInterval(timer);
          this.email = 'kentwidman@gmail.com';
        }
      },
      1000,
    );
  },
};
</script>

<style lang="scss">
@import '../scss/common';
.contact {
  background-position: top center;
  background-attachment: fixed;
  text-align: center;
  height: 100vh;
  min-height: 800px;

  .email {
    font-size: 24px;
    text-decoration: none;
    color: #fff;
    width: 100%;
    text-align: center;
  }

  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.info,
.social-links {
  text-align: left;
  max-width: 380px;
  font-size: 2.0em;
  margin: 60px auto;

  @media screen and (min-width: 640px) {
    font-size: 40px;
    max-width: 400px;
  }

  & > * {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }
}

.social-links__icon {
  width: calc(15% - 15px);
  text-align: right;

  @media screen and (min-width: 640px) {
    width: calc(15% - 15px);
  }
}

.social-links__text {
  width: calc(85% - 15px);

  @media screen and (min-width: 640px) {
    width: calc(85% - 15px);
  }

  &-small {
    font-size: 18px;

    @media screen and (min-width: 640px) {
      font-size: 28px;
    }
  }
}
</style>
